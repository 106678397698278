import logo from './logo.svg';
import './App.css';
import Hero from "../src/Components/Hero"
import LearnMore from './Components/LearnMore';
import Projects from './Components/Projects';
import Footer from './Components/Footer';
import Homepage from './Pages/Homepage';

function App() {
  return (
    <div className="App">
      <Homepage/>
    </div>
  );
}

export default App;
